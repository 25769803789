export enum EstatusUsuario {
    ACTIVO = "Activo",
    INACTIVO = "INACTIVO",
}

export enum TipoEdificio {
    TIPO1 = "TIPO1",
    TIPO2 = "TIPO2",
}

export enum TipoAula {
    AULA = "AULA",
    TALLER = "TALLER",
    OFICINAS = "OFICINAS",
}

export enum EstatusPlantelPersonal {
    ACTIVO = "ACTIVO",
    INACTIVO = "INACTIVO",
}

export enum EstatusCiclo {
    ACTIVO = "ACTIVO",
    INACTIVO = "INACTIVO",
}

export enum EstatusPeriodo {
    ACTIVO = "ACTIVO",
    INACTIVO = "INACTIVO",
    EN_PREPARACION = "EN PREPARACIÓN",
}

export enum TipoPeriodo {
    INICIAL = 'INICIAL',
    FINAL = 'FINAL',
    INTERSEMESTRAL = 'INTERSEMESTRAL',
}

export enum EstatusProceso {
    EN_PROCESO = "En proceso",
    TERMINADO  = "Terminado"
}

export enum TipoProceso {
    PREINSCRIPCION = "Preinscripción",
    INSCRIPCION = "Inscripción",
    REINSCRIPCION = "Reinscripción",
    REVALIDACION = "Revalidación",
    REGULARIZACION = "Regularización",
    EQUIVALENCIA = "Equivalencia",
    CARGA_CALIFICACIONES = "Carga de calificaciones",
    CIERRE_ORDINARIO = "Cierre ordinario",
    CIERRE_PERIODO = "Cierre semestre",
    TRASLADO = "Traslado",
    IMPORTACION = "Importación",
}

export enum EstatusAlumno {
    ACTIVO = "ACTIVO",
    INACTIVO = "INACTIVO",
    BAJA_TEMPORAL = "BAJA_TEMPORAL",
    BAJA_DEFINITIVA = "BAJA_DEFINITIVA",
    DESERCION = "DESERCION",
}

export enum SituacionAlumno {
    MATRICULADO = "MATRICULADO",
    REGULAR = "REGULAR",
    IRREGULAR = "IRREGULAR",
    REPETIDOR = "REPETIDOR",
    INACTIVO = "INACTIVO",
    BAJA_TEMPORAL = "BAJA TEMPORAL",
    BAJA_DEFINITIVA = "BAJA DEFINITIVA",
    DESERCION = "DESERCIÓN"
}

export enum TipoBaja {
    BAJA_TEMPORAL = "BAJA TEMPORAL",
    BAJA_DEFINITIVA = "BAJA DEFINITIVA",
}

export enum EstatusFichaPago {
}

export enum TipoPaquete {
    ASPIRANTE = "ASPIRANTE",
    ALUMNO = "ALUMNO",
    EQUIVALENCIA = "EQUIVALENCIA",
}

export enum EstatusPaquete {
    INACTIVO = "INACTIVO",
    ACTIVO = "ACTIVO",
}

export enum EstatusEnum {
    INACTIVO = "INACTIVO",
    ACTIVO = "ACTIVO"
}

export enum MesesVigencia {
  "1 MES" = 1,
  "2 MESES" = 2,
  "3 MESES" = 3,
  "4 MESES" = 4,
  "5 MESES" = 5,
  "6 MESES" = 6,
  "7 MESES" = 7,
  "8 MESES" = 8,
  "9 MESES" = 9,
  "10 MESES" = 10,
  "11 MESES" = 11,
  "12 MESES" = 12
}

export enum PrioridadAnuncio {
    ALTA = "ALTA",
    MEDIA = "MEDIA",
    BAJA = "BAJA",
}

export enum EstatusMateria {
    ACTIVA = "ACTIVA",
    INACTIVA = "INACTIVA"
}

export enum TipoMateria {
    TIPO1 = "TIPO1",
    TIPO2 = "TIPO2",
    TIPO3 = "TIPO3",
}

export enum TipoCalificacion {
    ORDINARIA = "ORDINARIA",
    REPETICION = "REPETICION",
    CONVALIDACION = "CONVALIDACION",
    CAMBIOCOMPONENTEOPCIONAL = "CAMBIO COMPONENTE OPCIONAL",
    REVALIDACION = "REVALIDACION",
    EQUIVALENCIA = "EQUIVALENCIA",
    REGULARIZACION = "REGULARIZACION"
}

export enum TipoCalificacionCambio {
    FINAL = "FINAL",
    PARCIAL = "PARCIAL",
}

export enum TipoEvaluacionEnum
{
    FORMATIVA = "FORMATIVA",
    SUMATIVA = "SUMATIVA"
}

export enum TipoRegularizacion
{
    RECUPERACION = "RECUPERACION",
    EXTRAORDINARIO1 = "EXTRAORDINARIO 1",
    EXTRAORDINARIO2 = "EXTRAORDINARIO 2",
    ESPECIAL = "ESPECIAL"
}

export enum TipoPeriodoCalificacion {
}

export enum TipoRecuperacion {
    RECUPERACION = "RECUPERACION",
    EXTRAORDINARIO = "EXTRAORDINARIO",
    ESPECIAL = "ESPECIAL",
}
export enum TipoMotivoBaja {
  PERSONALES, DISCIPLIBARIOS, ACADEMICOS
}
export enum TurnoEnum {
    MATUTINO = "MATUTINO",
    VESPERTINO = "VESPERTINO",
}

export enum TipoGrupo {
    FORMACION_BASICA = "FORMACION_BASICA",
    FORMACION_TRABAJO = "FORMACION_TRABAJO",
}

export enum EstatusFormacion {
    ACTIVO = "ACTIVO",
    INACTIVO = "INACTIVO",
}

export enum DiaSemana {
    LUNES = "LUNES",
    MARTES = "MARTES",
    MIERCOLES = "MIERCOLES",
    JUEVES = "JUEVES",
    VIERNES = "VIERNES",
    SABADO = "SABADO",
    DOMINGO = "DOMINGO",
}

export enum RepetidorAspirante
{
    CONTINUIDAD = "CONTINUIDAD",
    REPETIDOR = "REPETIDOR",
    SIN_ESTUDIAR = "SIN ESTUDIAR",
}

export enum Genero {
    Femenino = "Femenino",
    Masculino ="Masculino"
}

export enum tipoSangre{
    O_NEGATIVO ="O-",
    O_POSITIVO = "O+",
    A_NEGATIVO = "A-",
    A_POSITIVO ="A+",
    B_NEGATIVO = "B-",
    B_POSITIVO = "B+",
    AB_NEGATIVO = "AB-",
    AB_POSITIVO = "AB+",
    NO_ESPECIFICADO ="No especificado"
}

export enum EstadoRep{
    SINESPECIFICAR = "Sin especificar",
    AGUASCALIENTES = "Aguascalientes",
    BAJACALIFORNIA = "Baja California",
    BAJACALIFORNIASUR = "Baja California Sur",
    CAMPECHE = "Campeche",
    COAHUILA = "Coahuila",
    COLIMA = "Colima",
    CHIAPAS = "Chiapas",
    CHIHUAHUA = "Chihuaha",
    CDMX = "Ciudad de México",
    DURANGO = "Durango",
    GUANAJUATO ="Guanajuato",
    GUERRERO = "Guerrero",
    HIDALGO = "Hidalgo",
    JALISCO = "Jalisco",
    MEXICO = "Edo. de México",
    MICHOACAN = "Michoacan",
    MORELOS = "Morelos",
    NAYARIT = "Nayarit",
    NUEVOLEON = "Nuevo León",
    OAXACA = "Oaxaca",
    PUEBLA = "Puebla",
    QUERETARO = "Queretaro",
    QUINTANAROO = "Quintana Roo",
    SANLUISPOTOSI = "San Luis Potosí",
    SINALOA = "Sinaloa",
    SONORA = "Sonora",
    TABASCO = "Tabasco",
    TAMAULIPAS = "Tamaulipas",
    TLAXCALA = "Tlaxcala",
    VERACRUZ = "Veracruz",
    YUCATAN = "Yucatán",
    ZACATECAS = "Zacatecas"
}
export enum EstatusDocumentoTramite{
  SIN_CARGAR,
  CARGADO,
  OBSERVACIONES,
  APROBADOCEP,
  RECHAZADOCEDG,
  APROBADOCEDG,
  RECHAZADOCEP
}

export enum EstatusPago {
    SIN_PAGO = "Sin pago",
    EN_VALIDACION = "En validación",
    APROBADO = "Aprobado",
    CANCELADO = "Cancelado"
}

export enum EstatusMatriculacion{
    MATRICULADO = "Matriculado",
    PENDIENTE = "Pendiente"
}

export enum TipoTramite
{
    NUEVO_INGRESO = "Nuevo Ingreso",
    REGULAR = "Regular"
}

export enum EstatusDocumentacionAspirante{
  SIN_CARGA,
  CARGA_PARCIAL,
  OBSERVACIONES,
  APROBADO_CEP
}
export enum EstatusDocumentacionAspiranteCEDG{
  SIN_ASIGNAR,
  OBSERVACIONES,
  COMPLETO
}

export enum EstatusPagoAspirante {
    NO_APROBADO = "No aprobado",
    APROBADO = "Aprobado"
}

export enum EstatusPagoNumero {
    SIN_PAGO,
    EN_VALIDACION,
    APROBADO,
    CANCELADO,
}

export enum EstatusEquivalencia {
    PENDIENTE = "Pendiente",
    APROBADO = "Aprobado",
    RECHAZADO = "Rechazado",
}

export enum EstatusSolicitudEquivalencia {
    PENDIENTE,
    APROBADO,
    RECHAZADO
}

export enum Semestre {
  PRIMERO = "Primero", SEGUNDO ="Segundo", TERCERO ="Tercero", CUARTO = "Cuarto", QUINTO ="Quinto", SEXTO = "Sexto"
}

export enum SemestreValue {
    PRIMERO, SEGUNDO, TERCERO, CUARTO, QUINTO, SEXTO
}

export enum TipoEvaluacion {
    FORMATIVA = "Formativa",
    SUMATIVA = "Sumativa"
}

export enum EstadoCivil
{
    CASADO = "Casado", DIVORCIADO ="Divorciado", SEPARADO ="Separado", UNION_LIBRE ="Unión libre", VIUDO = "Viudo", SOLTERO = "Soltero"
}
export enum Parentesco
{
    PADRE = "Padre", MADRE = "Madre", TUTOR = "Tutor", HERMANO = "Hermano", HERMANA = "Hermana", ABUELO ="Abuelo", ABUELA ="Abuela", PRIMO ="Primo", PRIMA ="Prima",
    TIO ="Tio", TIA ="Tio", CONYUGE ="Cónyuge", AUTOREPRESENTANTE ="Autorepresentante"
}
export enum Profesion
{
    SERVIDOR_PUBLICO = "Servidor público", PROFESION_OFICIONISTA = "Profesión u oficio por su cuenta",
    EMPLEADO_EMPRESA = "Empleado de empresa particular", TRABAJADOR_AGRICULTOR = "Agric., ganad., camp., pescador", COMERCIANTE ="Comerciante o industrial",
    MARINO_MILITAR ="Marino o militar", OBRERO ="Obrero", PENSIONADO = "Pensionado", JUBILADO = "Jubilado", LABORES_HOGAR = "Labores del hogar"
}

export enum EstatusTraspaso{
  EN_SOLICITUD,
  ACEPTADO,
  RECHAZADO
}
export enum TipoActorTraslado{
  PLANTELORIGEN,
  PLANTELDESTINO,
  DIRECCIONGENERAL
}

export enum EstadoCalificacionRegularizacion{
    POR_ASIGNAR = "Por asignar",
    ASIGNADA = "Asignada",
    CALIFICADA = "Calificada",
    NO_USADA = "No usada"
}
export enum EstatusConvalidacion
{
    APROBADA, PENDIENTE, CANCELADA, DENEGADA
}
export enum RazonConvalidacion
{
    REPETICION, SOLICITUD
}
